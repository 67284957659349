/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../../../../../components/Layout'
import ThemeWrapper from '../../../../../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../../../../../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Okna, sítě. žaluzie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1420}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"&nbsp;Okna, dveře, příslušenství"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mozikmdoay"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/26c483f323984a3e8c31ef370ac039d9_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/35535/26c483f323984a3e8c31ef370ac039d9_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/26c483f323984a3e8c31ef370ac039d9_s=660x_.png 660w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--30" content={"Salamander&nbsp;bluevolution 92<br>nejvyšší řada<br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\">Energetická účinnost na nejvyšší úrovni. BluEvolution 92 je řešení, které kombinuje moderní technologie,\nfunkčnost a design na nejvyšší úrovni. Charakteristickým rysem systému je extrémně sněhově bílá barva a lesk\nprofilů.</span><span style=\"color: var(--black); font-weight: bold;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/d664cd74051b469083ac0a98d93da588_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":774}} srcSet={"https://cdn.swbpg.com/t/35535/d664cd74051b469083ac0a98d93da588_s=350x_.png 350w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pt--20" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/af56897a5cf642678d345dfc588b4aec_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/35535/af56897a5cf642678d345dfc588b4aec_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/af56897a5cf642678d345dfc588b4aec_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/af56897a5cf642678d345dfc588b4aec_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--30" content={"<br><br><br>Odstíny vašich oken"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><br>Dokonalá volba pro ty, kdo si cení eleganci a komfort.  Profily renomovaného německého producenta.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"aec36vhfgo"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"V-Perfect"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"color: var(--black);\">Při své práci používáme pouze nové technologie. V-Perfect je výsledkem přesného spojování profilů.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/945399aeec6c442f8b06dcc403018f64_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/945399aeec6c442f8b06dcc403018f64_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/35535/945399aeec6c442f8b06dcc403018f64_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/35535/945399aeec6c442f8b06dcc403018f64_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/a4276a128192436c86cf57bd36eb83a9_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/a4276a128192436c86cf57bd36eb83a9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/a4276a128192436c86cf57bd36eb83a9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/a4276a128192436c86cf57bd36eb83a9_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Acrylcolor"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"color: var(--black);\">Díky vrstvě akrylového skla je barva okna mnohem odolnější. Povlak odráží sluneční paprsky a neloupe se.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"uhrxwugsqq"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Vnější dveře"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"color: var(--black);\">Venkovní dveře se vyznačují vyšší odolností proti tepelným ztrátám a představují také bezpečnou bariéru proti hluku a nezvaným hostům. Rozhodněte se pro kvalitu, design a spolehlivost. Vyberte si vchodové dveře, které neplýtvají energií.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/064399405a1342b09731b18cef6a5c43_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/064399405a1342b09731b18cef6a5c43_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/064399405a1342b09731b18cef6a5c43_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/483edd2aab8042708b5da487f24e2d18_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/483edd2aab8042708b5da487f24e2d18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/483edd2aab8042708b5da487f24e2d18_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Žaluzie, síta, rolety"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"color: var(--black);\">Stínění je nejefektivnější způsob, jak zastínit váš dům, byt nebo pergolu. Při správném výběru dokáže mimo ochrany před sluncem uvnitř stíněného prostoru, vytvořit velmi příjemnou klimu. Samozřejmě jdeme s dobou jak technologií, tak i nadčasovým designem. Poradíme s výběrem vnitřního stínění i vnějšího stínění.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"k6jyvi8elrt"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/4cfd8d4782bc44acbbeae602f6468cdb_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/4cfd8d4782bc44acbbeae602f6468cdb_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Montáž, demontáž, servis oken a příslušenství"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"color: var(--black);\">Provádíme kompletní dodávky oken, dveří na klíč včetně příslušenství jako jsou parapety, zednické práce, malířské práce, servis oken.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}